import { User } from '@relewise/client';
import { getProductProperties, productVariantProperties } from '../requests/fetchRelewiseProducts';

export interface getRelewiseProductSearchQueryParams {
    locale: string;
    filters?: unknown[];
    facets?: unknown[];
    take: number;
    skip: number;
    term: string | null;
    sort?: {
        $type: string;
        attribute?: string;
        key?: string;
        mode?: string;
        order?: string;
    };
    user?: User;
    currency?: string;
}
export const getRelewiseProductSearchQuery = ({
    locale,
    filters,
    facets,
    take,
    skip,
    term,
    sort = undefined,
    user,
    currency,
}: getRelewiseProductSearchQueryParams) => {
    const sortOption = sort ?? {
        $type: 'Relewise.Client.DataTypes.Search.Sorting.Product.ProductRelevanceSorting, Relewise.Client',
        order: 'Descending',
        thenBy: null,
    };
    const searchRequest = {
        $type: 'Relewise.Client.Requests.Search.ProductSearchRequest, Relewise.Client',
        currency: {
            value: currency,
        },
        user: user,
        language: {
            value: locale,
        },
        sorting: {
            value: sortOption,
        },
        filters: {
            items: filters || [],
        },
        facets: {
            items: facets || [],
        },
        take: take,
        skip: skip,
        term: term,
        settings: {
            $type: 'Relewise.Client.Requests.Search.Settings.ProductSearchSettings, Relewise.Client',
            recommendation: {},
            selectedProductProperties: getProductProperties(locale),
            selectedVariantProperties: productVariantProperties,
        },
    };

    return searchRequest;
};
