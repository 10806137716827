import React from 'react';
import { M30ProductCarouselModule, ProductCarouselModuleLayoutEnum } from '~/lib/data-contract';
import { ModuleContainer } from '../ModuleContainer';
import { useRelewiseProducts } from './hooks/useRelewiseProducts';
import { ProductCollectionLayout } from '~/shared/components/ProductCollectionLayout/ProductCollectionLayout';

export const M30ProductCarousel = ({
    headline,
    subheadline,
    selectedProducts = [],
    callToAction,
    contentLayout,
    pageSize = '6',
    ...rest
}: M30ProductCarouselModule) => {
    const productIds = selectedProducts.map((item) => item.productId);
    const virtualPageSize =
        contentLayout === ProductCarouselModuleLayoutEnum.CAROUSEL
            ? selectedProducts.length
            : parseInt(pageSize);

    const { hasNextPage, fetchNextPage, products } = useRelewiseProducts({
        pageSize: virtualPageSize,
        productIds,
    });

    return (
        <ModuleContainer fullWidth {...rest}>
            <ProductCollectionLayout
                headline={headline}
                subheadline={subheadline}
                callToAction={callToAction}
                contentLayout={contentLayout}
                showLoadMore={hasNextPage}
                onLoadMoreClick={fetchNextPage}
                products={products}
            />
        </ModuleContainer>
    );
};
