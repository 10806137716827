import {
    dataFieldValueContainsFilter,
    FacetsBodyType,
    Product,
    ProductSearchRequestResponseType,
    specificProductIdsFilter,
} from '$templates/blocks/components/M140ProductsList';
import { User } from '@relewise/client';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { fetcher } from '~/lib/fetcher';
import { useFrame } from '~/shared/utils';
import { publicRuntimeConfig } from '~/shared/utils/public-variables';
import { usePage } from '~/templates/pages';
import { getRelewiseProductSearchQuery } from '../../M140ProductsList/components/lib/getRelewiseProductSearchQuery';
interface fetchProductsParams {
    pageParam: number;
    pageSize: number;
    locale: string;
    facets: FacetsBodyType[];
    filters: unknown[];
    productIds?: string[];
    sort?: {
        $type: string;
        attribute?: string;
        key?: string;
        mode?: string;
        order?: string;
    };
    user?: User;
    currency?: string;
}

export const fetchProducts = async ({
    pageParam,
    pageSize,
    productIds,
    facets,
    filters,
    locale,
    sort,
    user,
    currency,
}: fetchProductsParams) => {
    const {
        RELEWISE_API_URL,
        RELEWISE_ENVIRONMENT_ID,
        RELEWISE_SEARCH_API_KEY,
    } = publicRuntimeConfig();

    const contentSearchRequest = getRelewiseProductSearchQuery({
        locale: locale,
        take: pageSize,
        skip: pageParam * pageSize,
        term: null,
        filters: [
            specificProductIdsFilter(productIds),
            dataFieldValueContainsFilter({
                key: 'PublishStatus',
                target: 'product',
                matchType: 'Any',
                value: ['published'],
                equals: true,
            }),
            ...filters,
        ],
        facets: facets,
        sort: sort,
        user,
        currency,
    });

    return fetcher<ProductSearchRequestResponseType>(
        `${RELEWISE_API_URL}/${RELEWISE_ENVIRONMENT_ID}/v1/ProductSearchRequest`,
        {},
        { Authorization: `APIKey ${RELEWISE_SEARCH_API_KEY}` },
        'POST',
        JSON.stringify(contentSearchRequest)
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        });
};

export const getQueryKey = ({ productIds }: { productIds: string[] }) => [
    'M30RelewiseProducts',
    productIds,
];

export const getQueryOptions = () => ({
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 30, // 30 minutes cache time
    staleTime: 1000 * 60 * 30, // 30 minutes cache time
    retryOnMount: false,
    enabled: true,
});

export const useRelewiseProducts = ({
    pageSize,
    productIds = [],
}: {
    pageSize: number;
    productIds: string[];
}) => {
    const { market, culture } = usePage();
    const { data: frame } = useFrame();
    const locale = `${culture}-${market}`.toLowerCase();
    const [page, setPage] = useState(1);

    const queryKey = useMemo(() => {
        return getQueryKey({ productIds });
    }, [productIds]);

    const sortProductsByList = (products: Product[]): Product[] => {
        return products.sort((a, b) => {
            return (
                (productIds.indexOf(`${a.productId}`) as number) -
                (productIds.indexOf(`${b.productId}`) as number)
            );
        });
    };

    const { data, isLoading, ...restQuery } = useQuery(
        queryKey,
        () => {
            const params = {
                pageParam: 0, // In order to sort by the custom list of IDs we need to fetch all products at once
                locale: locale,
                pageSize: productIds.length,
                facets: [],
                filters: [],
                productIds: productIds,
                 currency: `${frame?.currency}-${culture.toUpperCase()}-${market}`,
            };

            return fetchProducts(params);
        },
        getQueryOptions()
    );

    const totalHits = useMemo(() => {
        return data?.hits ?? 0;
    }, [data]);

    const productList = useMemo(() => {
        const products = sortProductsByList(data?.results ?? []);
        const productsToReturn = pageSize * page;
        return products.slice(0, productsToReturn);
    }, [data?.results, pageSize, page]);

    const fetchNextPage = () => {
        setPage(page + 1);
    };

    const hasNextPage = useMemo(() => {
        return totalHits > pageSize * page;
    }, [page, totalHits, pageSize]);

    const isNoResults = (data?.hits ?? 0) == 0 && !isLoading;

    return {
        isLoading,
        ...restQuery,
        isNoResults,
        hasNextPage,
        fetchNextPage,
        totalHits,
        products: productList,
    };
};
